import { defineStore } from 'pinia'

export const useHomeStore = defineStore('homeStore', {
    state: () => ({
        items: [],
        item: {}
    }),
    actions: {
        async getList(){
            const { data } = await useFetch(`${useRequestURL().origin}/api/home`)
            const { status, response } = data.value
            if( status == 'success' ){
                this.items = response
            }
            return this.items
        },
        async getItem( id ){
            const { data } = await useFetch(`${useRequestURL().origin}/api/home/${id}`)
            const { status, response } = data.value
            if( status == 'success' ){
                this.item = response
            }
            return this.item
        }
    }
})